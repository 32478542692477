export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [0,2,3,4];

export const dictionary = {
		"/": [5],
		"/auth": [~6],
		"/auth/error": [7],
		"/auth/signin": [8],
		"/auth/signup": [9],
		"/dashboard": [10,[2]],
		"/dashboard/favorites": [~11,[2]],
		"/dashboard/search": [~12,[2]],
		"/dashboard/settings": [~13,[2]],
		"/dashboard/snippets": [~14,[2]],
		"/dashboard/snippets/new": [~17,[2]],
		"/dashboard/snippets/[snippetId]": [15,[2,3]],
		"/dashboard/snippets/[snippetId]/edit": [~16,[2,3]],
		"/dashboard/tags": [~18,[2]],
		"/dashboard/tags/new": [~21,[2]],
		"/dashboard/tags/[tagId]": [19,[2,4]],
		"/dashboard/tags/[tagId]/edit": [~20,[2,4]],
		"/demo": [22],
		"/privacy": [23],
		"/search": [~24],
		"/snippet/[snippetId]": [~25],
		"/tag/[tagId]": [~26],
		"/terms": [27]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';